<template>
  <static-fullscreen-card @sendHeight="setContentHeight">
    <template v-slot:title> ({{ data.id }}) {{ m.title + ` №${data.code_doc}` }} [{{ statusCurrent.text || "" }}] </template>
    <template v-slot:actions>
      <v-tooltip bottom v-if="hintStatus">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">fas fa-info-circle</v-icon>
        </template>
        <span v-html="hintStatus"></span>
      </v-tooltip>
      <a-btn-status
        v-if="!showOnly && getAccess('documents.loanGet.status', { users: [data.createdby_id] })"
        :items="statusItems"
        :hintStatus="hintStatus"
        @click="changeStatus($event)"
        :disabled="!canStatus"
      />
      <a-btn-edit v-if="!showOnly && accessEdit" @click="showHeadEdit = true" :disabled="canEdit" />
      <a-btn-delete
        v-if="!showOnly && id && data.status !== 2 && getAccess('documents.loanGet.delete', { users: [data.createdby_id] })"
        @click="removeDialogShow = true"
      />
    </template>
    <portal to="v-goup" v-if="idShow">
      <v-btn class="mr-3" fab small @click="$emit('close', data)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </portal>
    <v-row style="height: 100%">
      <v-col cols="12" sm="6" class="px-2">
        <material-card color="secondary">
          <template v-slot:heading> </template>
          <a-form-view :value="data" :model="modelLeft" :config="{ dense: true }" @click="onClick($event)" />
        </material-card>
      </v-col>
      <v-col cols="12" sm="6" class="px-2">
        <material-card color="secondary">
          <template v-slot:heading> </template>
          <a-btn-add @click="loanScheduleDialog()" :title="'График выплат'" :icon="'fas fa-layer-group'" />
          <a-form-view :value="data" :model="[...modelRight]" :config="{ dense: true }" />
        </material-card>
      </v-col>
      <v-col cols="12" sm="12" class="px-2" style="flex: 1">
        <material-card color="third" class="block_detail">
          <template v-slot:heading>
            <v-tabs v-model="tab" background-color="transparent" slider-color="white">
              <v-tab v-for="(tab, i) in tabs" v-if="tab.show" :key="i" :href="`#tabLoan-${tab.id}`" class="mr-3">
                {{ tab.title }}
              </v-tab>
            </v-tabs>
          </template>
          <v-tabs-items v-model="tab" class="transparent" style="min-height: 200px" touchless>
            <v-tab-item id="tabLoan-2" data-name="cash section">
              <v-card-text :style="styleDetailCard" class="pa-0">
                <a-table-f-api
                  ref="tableCashOut"
                  :api="'/accounting/doc/order_withdrawal'"
                  :model="getModelList($store.getters['config/get'].models.cashOut)"
                  :searchable="false"
                  :useQuery="false"
                  :defaults="{
                    sort: { key: 'id', order: 'DESC' },
                    filters: {
                      parent_name: m.accDocName,
                      parent_id: id,
                    },
                    paramName: 'documentCashOut',
                  }"
                  @click="onClickCash($event)"
                >
                  <template v-slot:top>
                    <v-fab-transition
                      v-if="
                        data.status == 2 &&
                        getAccess('documents.loanGet.edit', {
                          users: [data.createdby_id],
                        })
                      "
                    >
                      <v-btn fab dark color="green" absolute bottom small style="bottom: 15px" left class="v-btn--example" @click="showCashSelectDialog = true">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-fab-transition>
                  </template>
                </a-table-f-api>
              </v-card-text>
            </v-tab-item>
            <v-tab-item id="tabLoan-1" data-name="files section">
              <v-card-text :style="styleDetailCard" class="pa-0">
                <v-fab-transition v-if="true">
                  <v-btn fab dark color="green" absolute bottom small style="bottom: 25px" left class="v-btn--example" @click="addNewDocs('doc_all')">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-fab-transition>
                <a-view-filesdata :value="data.filesData" />
              </v-card-text>
            </v-tab-item>
            <v-tab-item id="tabLoan-3">
              <comment-view :id="id || 0" :targetName="'loanGet'" :needUpdate="needUpdateComments" :height="detailHeight" />
            </v-tab-item>
            <v-tab-item id="tabLoan-4">
              <v-card-text :style="styleDetailCard" class="pa-0">
                <s-document-acc-record
                  ref="accounting"
                  :id="id"
                  :name="m.accDocName"
                  :join="{ AccountingDocOrderWithdrawalModel: { parent_name: m.accDocName, parent_id: id } }"
                />
              </v-card-text>
            </v-tab-item>
            <v-tab-item id="tabLoan-5">
              <v-card-text :style="styleDetailCard" class="pa-0">
                <LoanSchedule :data="data" class="pb-3" />
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </material-card>
      </v-col>
    </v-row>
    <div v-if="loading" class="loading-block">
      <div class="loader"></div>
    </div>
    <portal to="v-main">
      <CashDialog ref="cashDialog" @updateData="$refs.tableCashOut.updateData()" />
      <LoanDialog v-model="showLoanEdit" :loan="data" @createSchedule="onCreateSchedule($event)" />
      <s-document-head v-model="showHeadEdit" v-if="showHeadEdit" :api="url" :m="m" :config="m.config.default" :title="m.title" :id="id" />
      <s-quickOpenDoc ref="quickOpenDoc" />
      <s-quick-addDocument v-if="showAddDocDialog" v-model="showAddDocDialog" :id="id" :api="url" :modelDoc="addDocModel" />
      <select-dialog v-model="showCashSelectDialog" v-if="showCashSelectDialog" :operations="operationsCash" @select="onSelectOperation($event)" />
      <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" :title="removeTitle" />
    </portal>
    <confirm-dialog ref="confirmDialog" />
    <change-value-dialog ref="changeValueDialog" />
    <a-loader v-if="loading" />

    <popup-menu v-model="popumMenuShow" :items="popupMenuList" :position="popupMenuPosition" @click="onClickMenu($event)" />
    <su-Data
      ref="suEdit"
      v-model="suEditShow"
      :api="api"
      :id="id"
      :activate="getAccess('suData')"
      :dataEdit="data"
      @show="suEditShow = true"
      v-if="getAccess('suData')"
    />
  </static-fullscreen-card>
</template>

<script>
//import { statusTask } from "@/config/modules/libs";
import cash from "@/components/s/document/cash";
import { autoHeightBlock, getAccess, popupMenu, removeEl, saveEl, genModel } from "@/components/mixings";
export default {
  mixins: [autoHeightBlock, getAccess, popupMenu, removeEl, saveEl, genModel, cash],
  components: {
    selectDialog: () => import("./../dialogs/cashOperationSelectDialog"),
    CashDialog: () => import("./../dialogs/cashDialog"),
    LoanDialog: () => import("./../dialogs/loanScheduleDialog"),
    LoanSchedule: () => import("./tabLoanSchedule"),
  },
  props: {
    idShow: Number,
    showOnly: { type: Boolean, default: false },
  },
  data() {
    return {
      id: 0,
      idEdit: 0,
      fieldsRO: [],

      needUpdateComments: 0,
      showLoanEdit: false,
      suEditShow: false,
      suEditConfig: null,
      showHeadEdit: false,
      showAddDocDialog: false,
      showList: false,
      removeDialogShow: false,
      removeTitle: "Вы действительно хотите удалить документ?",
      loading: false,
      loaded: false,
      title: "",
      data: { data_table: [[], []] },
      m: this.$store.getters["config/get"].models.documentLoanGet,
      statuses: this.$store.getters["config/get"].models.documentLoanGet.statuses,
      url: "/accounting/doc/getting_loan",
      api: "/accounting/doc/getting_loan",
      apiStatus: "/accounting/doc/getting_loan",
      tab: 0,
      blockHeight: 256,
      debit: null,
      credit: null,
      statusCur: null,
      hintStatus: "",
      addDocModel: null,
    };
  },
  created() {
    this.loading = true;
    this.$root.title = this.m.title;
    this.id = this.idShow || Number(this.$route.params.id);
    //    this.permit = this.getAccess("menu.objects");
    this.fitchData();
    this.loaded = true;
  },
  mounted() {},

  computed: {
    tabs() {
      let res = [
        { id: 1, title: "Документы", show: true },
        { id: 5, title: "График выплат", show: !true },
        { id: 2, title: "Расходные ордера", show: true },
        { id: 3, title: "Комментарии", show: true },
        { id: 4, title: "Проводки", show: this.getAccess("documents.loanGet.accounting") },
      ];
      return res;
    },
    modelLeft() {
      let model = this.calcModel("viewForm1");
      return model;
    },
    modelRight() {
      let model = this.calcModel("viewForm2");
      return model;
    },
    balance() {
      return { debit: this.debit, credit: this.credit };
    },
    canStatus() {
      if (!this.data.credit_payment_schedule_id && this.data.status != 2) {
        this.hintStatus += "Необходимо создать график выплат<br/>";
        return false;
      }
      return true;
    },
    canEdit() {
      return this.loading || false;
    },
    accessEdit() {
      let res =
        this.data.status !== 2 &&
        this.getAccess("documents.loanGet.edit", {
          users: [this.data.createdby_id],
        });
      return res;
    },

    model: {
      get() {
        let type = this.data.type || 1;
        //    let model = JSON.parse(JSON.stringify(this.m.form));
        let model = this.getModelList(this.m, "form", true);

        this.modelReady = true;

        return model;
      },
    },
    statusItems() {
      if (!this.data.status) return [];
      let items = [];
      let res = [];
      const statusCur = this.statusCurrent;
      if (statusCur && statusCur.next) {
        let arr = statusCur.next;
        res = this.statuses.filter((s) => {
          return arr.includes(s.value);
        });
      }
      items = JSON.parse(JSON.stringify(res));

      return items;
    },
    statusCurrent() {
      let statusCur = this.statuses.find((el) => el.value == this.data.status);
      if (!statusCur) {
        statusCur = {};
      }
      return JSON.parse(JSON.stringify(statusCur));
    },
    operationsCash() {
      let res = [...(this.m.operationsCash?.[this.data.operation_type] || [])].filter((f) => {
        return f != 51 || this.data.first_payment_type == 1;
      });
      //если оплата первоначального взноса инвестором, то отключаем операцию
      console.log("operationsCash", res, this.m.operationsCash, this.data.operation_type);
      return res;
    },
  },
  watch: {
    showHeadEdit(v) {
      if (!v) {
        this.fitchData();
      }
    },
  },
  methods: {
    loanScheduleDialog() {
      this.showLoanEdit = true;
    },
    onCreateSchedule(e) {
      let data = { id: this.data.id, credit_payment_schedule_id: e };
      if (this.save(this.api, data)) this.data.credit_payment_schedule_id = e;
    },
    addNewDocs(field) {
      this.addDocModel = this.model.find((el) => el.name == field) || null;

      if (!this.addDocModel) return;
      this.showAddDocDialog = true;
    },
    async fitchData() {
      this.loading = true;
      const d = await this.$axios.get(this.url + "/" + this.id);
      if (d.data && d.data.data) {
        this.data = d.data.data;
      }
      for (let el of this.model) {
        if (el?.formula) {
          this.makeFormulaField(el);
        }
      }
      this.afterFetchData();
      this.loading = false;
    },
    makeFormulaField(el) {
      let f = el.formula;
      Object.defineProperty(this.data, el.name, {
        get: function () {
          let res = 0;
          let func;
          if (typeof f == "function") func = f;
          if (typeof f == "string") func = eval(f);
          res = func(this);
          return res;
        },
      });
    },
    onClickRow(e, type = "") {
      console.log("onClickRow", e);
      if (e.field?.isMenu) {
        if (["base_doc_name", "name_doc"].includes(e.field.name)) {
          console.log("onClickRow111");

          let menuItems = [
            {
              name: e.field.name,
              title: "Открыть документ",
              action: async () => {
                this.$refs.quickOpenDoc.openDocument(e.row.base_name, e.row.base_id, e.row);
              },
            },
          ];
          this.showPopupMenu(e.field.name, e.row, e.event, { menuItems });
        } else this.showPopupMenu(name, e.row, e.event, { task: { id: e.row[e.field.name] } });
      } else if (e.field.name == "actions") {
      } else {
        this.idEdit = e.row.id;
        if (type == "service") {
          this.showDialogServiceRowEdit = true;
          return;
        }
        this.fieldsRO =
          this.data.operation_type == 1 //по чек-листу
            ? ["name", "amount", "measure", "price_plan"]
            : [];
        this.showDialogRowEdit = true;
      }
    },

    afterRemove(data) {
      this.removeDialogShow = false;
      if (this.idShow) this.$emit("close", data);
      else this.$router.push({ name: "loanGet" });
    },
    afterFetchData(r) {
      if (this.id) {
      }
      this.loaded = true;
    },

    async changeStatus(status) {
      let ok = false;
      if (status.value == 2 && !this.data.credit_payment_schedule_id) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Необходимо создать график выплат",
        });
        return;
      }
      ok = await this.$refs.confirmDialog.show({
        title: "Изменение статуса",
        message: [`Вы действительно хотите ${status.value !== 2 ? "распровести" : "провести"} заказ?`],
        okButton: "Да",
        cancelButton: "Нет",
      });
      if (!ok) return;
      if (status.value !== 2) {
        let resp = await this.$axios.post("accounting/records/report-sql", {
          q: `select id from accounting_doc_order_withdrawal where parent_id=${this.id} and parent_name="${this.m.accDocName}"  and deleted=0`,
        });
        if (resp.data.status == "ok") {
          if (resp.data.data.length > 0) {
            this.$root.$emit("show-info", {
              type: "error",
              text: "Не возможно сменить статус. Есть связанный документ оплаты",
            });
            return;
          }
        } else {
          this.$root.$emit("show-info", {
            type: "error",
            text: "Не возможно сменить статус. Произошла ошибка проверки",
          });
          return;
        }
      }
      this.loading = true;
      let data = { id: this.id, status: status.value };
      let response = await this.$axios.post(this.apiStatus, data);
      this.loading = false;
      status = response.data.status == "ok";
      if (response.data.data) {
        Object.assign(this.data, response.data.data);
        this.$root.$emit("show-info", {
          text: "Статус изменен",
        });
      }
      this.$refs.accounting.refresh();
    },
    onSelectOperation(e) {
      if (!e.value) return;
      this.$refs.cashDialog.openDialog(e.id, 0, this.data, { operation_name: e.text });
    },
  },
};
</script>
